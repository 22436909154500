import { Controller }from "@hotwired/stimulus"
import ReactDOM  from "react-dom/client";
import First from "../components/First";

// Example of a React Controller. 

export default class extends Controller {

  connect() {

    console.log("React controller connected !")
    let root = ReactDOM.createRoot(this.element);
    root.render(First);
  }
}
